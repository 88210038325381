import fetch from 'auth/FetchInterceptor'
import * as qs from 'qs'

const JwtAuthService = {}

JwtAuthService.login = function (data) {
	var qs = require('qs');
	return fetch({
		url: '/login',
		method: 'post',
		headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    },
	data:qs.stringify(data)
		
	})
}
JwtAuthService.homepagebusiness = function()
{
	return fetch({
		url: '/api_business_manager',
		method: 'get',
	})
}
JwtAuthService.all_groups = function()
{
	return fetch({
		url: '/all_groups',
		method: 'get',
	})
}
JwtAuthService.groupUpdate = function(data)
{
	return fetch({
		url: '/groupUpdate',
		method: 'post',
		data:data
	})
}

JwtAuthService.course_analytics = function(data)
{
	return fetch({
		url: '/course_analytics',
		method: 'get',
		params:data
	})
}
JwtAuthService.pendingInvite = function()
{
	return fetch({
		url: '/pendingInvite',
		method: 'get',
	})
}
JwtAuthService.api_invite_manager = function(param)
{
	return fetch({
		url: `/api_invite_manager${param}`,
		method: 'get',
	})
}
JwtAuthService.business_detail = function()
{
	return fetch({
		url: '/business_detail',
		method: 'get',
	})
}
JwtAuthService.new_courses = function()
{
	return fetch({
		url: '/new_courses',
		method: 'get',
	})
}
JwtAuthService.leaderboard = function(params = null)
{
	return fetch({
		url: '/leaderboard',
		method: 'get',
	})
}

JwtAuthService.leaderboardparam = function(params)
{
	return fetch({
		url: '/leaderboardSearch',
		method: 'get',
		params:params
	})
}
JwtAuthService.usercomments = function()
{
	return fetch({
		url: '/usercomments',
		method: 'get',
	})
}
JwtAuthService.user_profile = function(param)
{
	return fetch({
		url: '/user_profile/'+param,
		method: 'get',
	})
}
JwtAuthService.api_user_course_analytics = function(param)
{
	return fetch({
		url: '/api_user_course_analytics/'+param,
		method: 'get',
	})
}
JwtAuthService.api_courses = function()
{
	return fetch({
		url: '/api_courses',
		method: 'get',
	})
}
JwtAuthService.business_assigments = function(param)
{
	return fetch({
		url: '/business_assigments',
		method: 'post',
		data:param
	})
}
JwtAuthService.get_business_assigments = function()
{
	return fetch({
		url: '/get_business_assigments',
		method: 'get'
	})
}
JwtAuthService.delete_business_assigments = function(param)
{
	return fetch({
		url: '/delete_business_assigments/'+param,
		method: 'get'
	})
}
JwtAuthService.api_get_user_logs = function(param)
{
	return fetch({
		url: '/api_get_user_logs/'+param,
		method: 'get',
	})
}

JwtAuthService.workerUpdate = function(data)
{
	return fetch({
		url: '/workerUpdate',
		method: 'post',
		data:data
	})
}
JwtAuthService.deleteContent = function(data)
{
	return fetch({
		url: '/deleteContent',
		method: 'post',
		data:data
	})
}
JwtAuthService.sendInvite = function(data)
{
	return fetch({
		url: '/sendInvite',
		method: 'post',
		data:data
	})
}
JwtAuthService.group_time_weekly_monitor = function(data)
{
	return fetch({
		url: '/group_time_weekly_monitor',
		method: 'get',
		params:data
	})
}


JwtAuthService.userdata = function (data) {
	var qs = require('qs');
	return fetch({
		url: '/userData',
		method: 'post',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
		},
		
		
	})
}

JwtAuthService.txtFile = function (data) {
	var qs = require('qs');
	return fetch({
		url: '/user/txtfile',
		method: 'post',
		data:qs.stringify(data)
		
	})
}

JwtAuthService.saveTxtFile = function (data) {
	var qs = require('qs');
	return fetch({
		url: '/user/txt',
		method: 'post',
		data:qs.stringify(data)
		
	})
}
/* Backlink Data */
JwtAuthService.getTxtFile = function (data = "") {
	return fetch({
		url: data!=""?'/user/txt?'+data:'/user/txt',
		method: 'get',
		
	})
}

JwtAuthService.getTxtData = function (data) {
	return fetch({
		url: '/user/txtData',
		method: 'post',
		data:qs.stringify(data)
	})
}

/* Comment Data */
JwtAuthService.getComments = function(){
	return fetch({
		url:'/user/comments',
		method:'get'
	})
}
JwtAuthService.saveComment = function(data){
	var qs = require('qs');
	return fetch({
		url: '/user/comments',
		method: 'post',
		data:qs.stringify(data)
		
	})
}



/* Google Rank Tools */

/* Domains */
JwtAuthService.getDomains = function(data){
	var qs = require('qs');
	return fetch({
		url: '/user/getdomains',
		method: 'post',
		data:qs.stringify(data)
		
	})
}

JwtAuthService.getDomain = function(data){
	var qs = require('qs');
	return fetch({
		url: '/user/getdomain',
		method: 'post',
		data:qs.stringify(data)
		
	})
}


JwtAuthService.setDomains = function(data){
	var qs = require('qs');
	return fetch({
		url: '/user/setdomains',
		method: 'post',
		data:qs.stringify(data)
	})
}

JwtAuthService.getKeyword = function(data){
	var qs = require('qs');
	return fetch({
		url: '/user/getkeyword',
		method: 'post',
		data:qs.stringify(data)
	})
}

/* Search */
JwtAuthService.getSearchKey = function(data){
	var qs = require('qs');
	return fetch({
		url: '/user/getsearchkey',
		method: 'post',
		data:qs.stringify(data)
		
	})
}


/* User Data */
JwtAuthService.getUserData = function(){
	return fetch({
		url:'/user/getUser',
		method:'post'
	})
}

JwtAuthService.signUp = function (data) {
	return fetch({
		url: '/auth/signup',
		method: 'post',
		headers: {
      'public-request': 'true'
    },
		data: data
	})
}

export default JwtAuthService